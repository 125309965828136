<template>
  <v-container>
    <v-btn @click="connect">connect</v-btn>

    <div v-html="data">
    </div>
  </v-container>
</template>

<script>

export default {
  name: "Plan",
  components: {},
  data: () => ({
    data: 'wait to start...'
  }),
  methods: {
    async connect() {
      // 通过浏览器请求蓝牙设备
      const device = await navigator.bluetooth.requestDevice({
        optionalServices: ["battery_service", "device_information"],
        acceptAllDevices: true,
      });
      this.data = device
      // 连接到 GATT 服务器
      // 我们还在这里获得了蓝牙设备的名称
      let deviceName = device.gatt.device.name;
      const server = await device.gatt.connect();
      // 通过 GATT 服务器获取我们之前提到的服务
      const batteryService = await server.getPrimaryService("battery_service");
      const infoService = await server.getPrimaryService("device_information");
      // 获取当前电池电量
      const batteryLevelCharacteristic = await batteryService.getCharacteristic(
          "battery_level"
      );
      // 将收到的缓冲区转换为数字
      const batteryLevel = await batteryLevelCharacteristic.readValue();
      const batteryPercent = await batteryLevel.getUint8(0);
      // 获取设备信息
      // 我们将从 device_information 中获取所有特征
      const infoCharacteristics = await infoService.getCharacteristics();
      console.log(infoCharacteristics);
      let infoValues = [];
      const promise = new Promise((resolve, reject) => {
        infoCharacteristics.forEach(async (characteristic, index, array) => {
          // Returns a buffer
          const value = await characteristic.readValue();
          console.log(new TextDecoder().decode(value));
          // Convert the buffer to string
          infoValues.push(new TextDecoder().decode(value));
          if (index === array.length - 1) resolve();
        });
      });
      promise.then(() => {
        // 在屏幕上显示所有信息
        // 使用innerHTML
        this.data = `
    Device Name - ${deviceName}<br />
    Battery Level - ${batteryPercent}%<br />
    Device Information:
    <ul>
      ${infoValues.map((value) => `<li>${value}</li>`).join("")}
    </ul>
  `;
      });
    }
  }
}
</script>

<style scoped>

</style>